import React from "react";
// import { submitContactForm } from "./Apiservice";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <div>
      <div className="page-title" data-aos="fade">
        <div className="heading" id="Contact-banner">
          <div className="container">
            <div className="row d-flex justify-content-center text-center">
              {/* <div className="col-lg-8 mt-lg-3">
                <h1>Contact</h1>
              </div> */}
            </div>
          </div>
        </div>
        {/* <nav className="breadcrumbs">
        <div className="container">
          <ol>
            <li><Link to="/">Home</Link></li>
            <li className="current">Contact</li>
          </ol>
        </div>
      </nav> */}
      </div>
      <div className="contact-main container mt-5">
        <div className="contactform-main ">
          <section id="contact" className="contact section">
            <div
              className="container mt-5"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="col ">
                <div className="row d-flex justify-content-center align-items-start">
                  <div
                    className="info-item d-flex col-lg-4"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <i className="bi bi-geo-alt flex-shrink-0"></i>
                    <div className="">
                      <h3>Address</h3>
                      <p>
                        St. Marcina’s Matric Hr Sec School, No. 27, Vandaiyar
                        Colony, Ammapet, Thanjavur - 614401
                      </p>
                    </div>
                  </div>

                  <div
                    className="info-item d-flex col-lg-4"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <i className="bi bi-telephone flex-shrink-0"></i>
                    <div>
                      <h3>Call Us</h3>
                      <p>
                        <Link to="tel:04374232485">
                          <span> 04374-232485 | </span>
                        </Link>
                        <Link to="tel:+918056377905">
                          <span> +918056377905</span>
                        </Link>{" "}
                      </p>
                    </div>
                  </div>

                  <div
                    className="info-item d-flex col-lg-4"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  >
                    <i className="bi bi-envelope flex-shrink-0"></i>
                    <div>
                      <h3>Email Us</h3>
                      <Link to="mailto:stmarcinasmhsschool@gmail.com">
                        <span> stmarcinasmhsschool@gmail.com</span>
                      </Link>
                    </div>
                  </div>
                </div>

                {/* <div className="col-lg-12">
                  <form
                    onSubmit={handleSubmit}
                    className="php-email-form"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <div className="row gy-4">
                      <div className="col-md-6">
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="Your Name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </div>

                      <div className="col-md-6">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          placeholder="Your Email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="number"
                          name="mobile"
                          className="form-control"
                          placeholder="Mobile"
                          value={formData.mobile}
                          onChange={handleChange}
                          required
                        />
                      </div>

                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          name="location"
                          placeholder="Location"
                          value={formData.location}
                          onChange={handleChange}
                          required
                        />
                      </div>

                      <div className="col-md-12">
                        <textarea
                          className="form-control"
                          name="message"
                          rows="6"
                          placeholder="Message"
                          value={formData.message}
                          onChange={handleChange}
                          required
                        ></textarea>
                      </div>

                      <div className="col-md-12 text-center">
                        {loading && <div className="loading">Loading...</div>}
                        {error && <div className="error-message">{error}</div>}
                        {successMessage && (
                          <div className="sent-message">{successMessage}</div>
                        )}

                        <button type="submit" disabled={loading}>
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                </div> */}
              </div>
            </div>
            <div
              className="mb-5 map-container mt-3"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.3591553949013!2d79.34386107504197!3d10.783780089365441!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baab5701ddb03ff%3A0x84b8093960af130d!2sSt.Marcina&#39;s%20Matriculation%20Higher%20Secondary%20School%2C%20Ammapettai!5e0!3m2!1sen!2sin!4v1722234465772!5m2!1sen!2sin"
                style={{ height: "400px", width: "100%" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </section>
        </div>

        <div className="contactimg-cards">
          <div className="contactimg-card">
            <div className="contactimg-card__img">
              <img
                src="/assets/img/contactpage/contact1.jpg"
                alt=""
                style={{ borderRadius: "10px" }}
              />
            </div>
          </div>

          <div className="contactimg-card">
            <div className="contactimg-card__img">
              <img
                src="/assets/img/contactpage/contact2.jpg"
                alt=""
                style={{ borderRadius: "10px" }}
              />
            </div>
          </div>

          {/* <div className="contactimg-card">
            <div className="contactimg-card__img">
              <img
                src="/assets/img/contactpage/contact3.jpg"
                alt=""
                style={{ borderRadius: "10px" }}
              />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Contact;
