import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <footer id="footer" class="footer position-relative light-background ">
        <div class="container footer-top  ">
          <div class="row gy-4 ">
            <div class="col-lg-4 col-md-6 footer-about ">
              <Link to="/" class="logo d-flex align-items-center ">
                <img src="assets/img/Logo.png" alt="" />
                <span class="sitename">ST. MARCINA'S SCHOOL </span>
              </Link>
              <div class="footer-contact pt-3 ">
                <p>No. 27, Vandaiyar Colony, </p>
                <p>Ammapet, Thanjavur - 614401</p>
                <p class="mt-3">
                  <strong>Phone: </strong>
                  <Link to="tel:04374232485">
                    <span style={{ fontFamily: "sans-serif" }}>
                      {" "}
                      04374-232485 |{" "}
                    </span>
                  </Link>
                  <Link to="tel:+918056377905">
                    <span style={{ fontFamily: "sans-serif" }}>
                      {" "}
                      +91 8056377905
                    </span>
                  </Link>
                </p>
                <p>
                  <strong>Email: </strong>
                  <Link to="mailto:stmarcinasmhsschool@gmail.com">
                    <span> stmarcinasmhsschool@gmail.com</span>
                  </Link>
                </p>
              </div>
            </div>

            <div class="col-lg-4 col-md-3 footer-links ">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <Link to="/">
                    Home<br></br>
                  </Link>
                </li>
                <li>
                  <Link to="/about">About us</Link>
                </li>
                <li>
                  <Link to="/Vision">Vision&Mission</Link>
                </li>
                <li>
                  <Link to="/Facilities">Facilities</Link>
                </li>
                <li>
                  <Link to="/News&events">News&Events</Link>
                </li>
                <li>
                  <Link to="/gallery">Gallery</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>

            <div class="col-lg-4 col-md-3 footer-links">
              <h4>Social Links</h4>

              <div class="social-links d-flex justify-content-center mt-4">
                <Link to="https://youtube.com/@stmarcinasmhsschool1982">
                  <i class="bi bi-youtube" style={{ color: "red" }}></i>
                </Link>
                <Link to="https://www.facebook.com/stmarcinasmhss">
                  <i class="bi bi-facebook" style={{ color: "blue" }}></i>
                </Link>
                <Link to="https://instagram.com/stmarcinasschool">
                  <i
                    class="bi bi-instagram"
                    style={{ color: "rgb(237 97 97)" }}
                  ></i>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div class="container copyright text-center mt-4">
          <p>
            © <span>Copyright</span>{" "}
            <strong class="px-1 sitename">ST. MARCINA'S SCHOOL</strong>{" "}
            <span>All Rights Reserved</span>
          </p>
          <div class="credits">
            Designed by{" "}
            <Link to="https://brandingworld.net/">BrandingWorld</Link>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
