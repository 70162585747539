// import React from "react";
// import { Link } from "react-router-dom";

// const Events = () => {
//   return (
//     <div>
//       <div class="page-title" data-aos="fade">
//         <div class="heading" id="event-banner">
//           <div class="container">
//             <div class="row d-flex justify-content-center text-center">
//               {/* <div class="col-lg-8 mt-lg-5">
//                   <h1>News & Event</h1>
//                 </div> */}
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="projcard-container">
//         {/* <div className="projcard projcard-green">
//           <div className="projcard-innerbox">
//             <img className="projcard-img" src="assets/img/events/yoga.jpg" />
//             <div className="projcard-textbox">
//               <div className="projcard-title">International Yoga Day</div>

//               <div className="projcard-bar"></div>
//               <div className="projcard-description">
//                 To promote physical well-being and mindfulness, our students
//                 from Grades 1 to 12 participated in a series of yoga activities.
//                 This event was a remarkable success, highlighting the importance
//                 of physical fitness and mental health.
//               </div>
//             </div>
//           </div>
//         </div> */}
//         <div className="projcard projcard-red">
//           <div className="projcard-innerbox">
//             <img className="projcard-img" src="assets/img/events/yoga.jpg" />
//             <div className="projcard-textbox">
//               <div className="projcard-title">International Yoga Day</div>

//               <div className="projcard-bar"></div>
//               <div className="projcard-description">
//                 To promote physical well-being and mindfulness, our students
//                 from Grades 1 to 12 participated in a series of yoga activities.
//                 This event was a remarkable success, highlighting the importance
//                 of physical fitness and mental health.
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="projcard projcard-red">
//           <div className="projcard-innerbox">
//             <img className="projcard-img" src="assets/img/events/doctors.jpg" />
//             <div className="projcard-textbox">
//               <div className="projcard-title">National Doctor’s Day</div>

//               <div className="projcard-bar"></div>
//               <div className="projcard-description">
//                 Observed by students in Grades 9 to 12, this day was dedicated
//                 to raising awareness about common diseases and health practices
//                 among younger students. It aimed to foster a deeper
//                 understanding of healthcare and encourage healthy living.
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="projcard projcard-blue">
//           <div className="projcard-innerbox">
//             <img className="projcard-img" src="assets/img/events/Chess.jpg" />
//             <div className="projcard-textbox">
//               <div className="projcard-title">International Chess Day</div>
//               <div className="projcard-bar"></div>
//               <div className="projcard-description">
// A chess competition was held for students in Grades 6 to 12.
// This event not only tested the strategic thinking and
// problem-solving skills of the participants but also promoted the
// value of intellectual pursuits.
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="projcard projcard-blue">
//           <div className="projcard-innerbox">
//             <img
//               className="projcard-img"
//               src="assets/img/events/thanjavur.jpg"
//             />
//             <div className="projcard-textbox">
//               <div className="projcard-title">Thanjavur Book Festival</div>

//               <div className="projcard-bar"></div>
//               <div className="projcard-description">
//                 Our Grade 12 students had the opportunity to visit the Thanjavur
//                 Book Festival organized by the Government. This field trip was
//                 designed to enhance their appreciation for literature and
//                 broaden their educational horizons.
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="projcard projcard-green">
//           <div className="projcard-innerbox">
//             <img
//               className="projcard-img"
//               src="assets/img/events/NatureconservationDay.jpg"
//             />
//             <div className="projcard-textbox">
//               <div className="projcard-title">
//                 World Nature Conservation Day
//               </div>

//               <div className="projcard-bar"></div>
//               <div className="projcard-description">
//                 In celebration of World Nature Conservation Day, a drawing
//                 competition was organized for students from Grades 1 to 12. This
//                 initiative aimed to foster environmental awareness and
//                 creativity among our students.
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="projcard projcard-red">
//           <div className="projcard-innerbox">
//             <img className="projcard-img" src="assets/img/events/carom.jpg" />
//             <div className="projcard-textbox">
//               <div className="projcard-title">International Carrom Day</div>
//               <div className="projcard-bar"></div>
//               <div className="projcard-description">
//                 Students in Grades 6 to 12 competed in a Carrom tournament. This
//                 event was part of our efforts to encourage strategic thinking
//                 and dexterity through traditional games.
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="projcard projcard-red">
//           <div className="projcard-innerbox">
//             <img
//               className="projcard-img"
//               src="assets/img/events/independence.jpg"
//             />
//             <div className="projcard-textbox">
//               <div className="projcard-title">
//                 Independence Day & Founder’s Day
//               </div>
//               <div className="projcard-bar"></div>
//               <div className="projcard-description">
//                 Our Independence Day celebrations were also an occasion to
//                 commemorate Founder’s Day. We honored meritorious students and
//                 enjoyed a variety of cultural activities, reinforcing our
//                 school’s values and spirit.
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="projcard projcard-green">
//           <div className="projcard-innerbox">
//             <img
//               className="projcard-img"
//               src="assets/img/events/greenday2.jpg"
//             />
//             <div className="projcard-textbox">
//               <div className="projcard-title">Green Day</div>

//               <div className="projcard-bar"></div>
//               <div className="projcard-description">
//                 A special Green Day celebration was held for our Kindergarten
//                 students, emphasizing the importance of environmental
//                 conservation in a fun and engaging way.
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="projcard projcard-red">
//           <div className="projcard-innerbox">
//             <img className="projcard-img" src="assets/img/events/Sudoku.jpg" />
//             <div className="projcard-textbox">
//               <div className="projcard-title">International Sudoku Day</div>

//               <div className="projcard-bar"></div>
//               <div className="projcard-description">
//                 To celebrate International Sudoku Day, a Sudoku competition was
//                 conducted for students across all grades. This event was aimed
//                 at enhancing students’ problem-solving skills and logical
//                 thinking.
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="projcard projcard-blue">
//           <div className="projcard-innerbox">
//             <img
//               className="projcard-img"
//               src="assets/img/events/Engineersday.jpg"
//             />
//             <div className="projcard-textbox">
//               <div className="projcard-title">National Engineer’s Day</div>
//               <div className="projcard-bar"></div>
//               <div className="projcard-description">
//                 Observed by students in Grades 9 to 12, this day focused on
//                 raising awareness about basic engineering concepts and inspiring
//                 interest in engineering fields.
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="projcard projcard-red">
//           <div className="projcard-innerbox">
//             <img
//               className="projcard-img"
//               src="assets/img/events/Savingsday.jpg"
//             />
//             <div className="projcard-textbox">
//               <div className="projcard-title">World Savings Day</div>

//               <div className="projcard-bar"></div>
//               <div className="projcard-description">
//                 This event, held for Grades 1 and 12, aimed to educate students
//                 about the importance of saving and financial literacy, helping
//                 them develop sound financial habits.
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="projcard projcard-green">
//           <div className="projcard-innerbox">
//             <img className="projcard-img" src="assets/img/events/Origami.jpg" />
//             <div className="projcard-textbox">
//               <div className="projcard-title">World Origami Day</div>

//               <div className="projcard-bar"></div>
//               <div className="projcard-description">
//                 Our students from Grades 1 to 12 participated in an Origami
//                 competition, showcasing their creativity and fine motor skills
//                 while learning about the art of paper folding.
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="projcard projcard-blue">
//           <div className="projcard-innerbox">
//             <img
//               className="projcard-img"
//               src="assets/img/events/christmas.jpg"
//             />
//             <div className="projcard-textbox">
//               <div className="projcard-title">Christmas Celebration</div>
//               <div className="projcard-bar"></div>
//               <div className="projcard-description">
//                 Christmas was celebrated with great enthusiasm and festivity.
//                 The event brought joy and togetherness to our school community.
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="projcard projcard-red">
//           <div className="projcard-innerbox">
//             <img
//               className="projcard-img"
//               src="assets/img/events/TalentHunt.jpg"
//             />
//             <div className="projcard-textbox">
//               <div className="projcard-title">Talent Hunt 2023-24</div>

//               <div className="projcard-bar"></div>
//               <div className="projcard-description">
//                 The Talent Hunt was an initiative to uncover and showcase the
//                 diverse talents of our students in music, dance, singing, mime,
//                 and mimicry, highlighting their creativity and skills.
//               </div>
//             </div>
//           </div>
//         </div>
//         <div
//           className="projcard projcard-customcolor"
//           style={{ color: " #F5AF41" }}
//         >
//           <div className="projcard-innerbox">
//             <img className="projcard-img" src="assets/img/events/pongal.jpg" />
//             <div className="projcard-textbox">
//               <div className="projcard-title">Pongal Celebration</div>

//               <div className="projcard-bar"></div>
//               <div className="projcard-description">
//                 {" "}
//                 Pongal was celebrated with traditional fervor, including sports
//                 such as tug of war, kabadi, and uriyadi. This event emphasized
//                 our commitment to preserving cultural heritage and fostering
//                 team spirit.
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="projcard projcard-red">
//           <div className="projcard-innerbox">
//             <img
//               className="projcard-img"
//               src="assets/img/events/scienceday.jpg"
//             />
//             <div className="projcard-textbox">
//               <div className="projcard-title">National Science Day</div>

//               <div className="projcard-bar"></div>
//               <div className="projcard-description">
//                 To promote scientific inquiry, a Science Exhibition was
//                 organized for Grades 6 and 12. This event provided students with
//                 an opportunity to display their scientific projects and
//                 research.
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="projcard projcard-blue">
//           <div className="projcard-innerbox">
//             <img
//               className="projcard-img"
//               src="assets/img/events/sportmeet.jpg"
//             />
//             <div className="projcard-textbox">
//               <div className="projcard-title">Sports Meet 2023-24</div>
//               <div className="projcard-bar"></div>
//               <div className="projcard-description">
//                 The annual Sports Meet was held to celebrate and encourage
//                 athletic talent among students. The event was a platform for
//                 students to demonstrate their skills and sportsmanship.
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="projcard projcard-blue">
//           <div className="projcard-innerbox">
//             <img
//               className="projcard-img"
//               src="assets/img/events/MotherLanguage.jpg"
//             />
//             <div className="projcard-textbox">
//               <div className="projcard-title">
//                 International Mother Language Day
//               </div>
//               <div className="projcard-bar"></div>
//               <div className="projcard-description">
//                 Various competitions in Tamil were organized to celebrate
//                 International Mother Language Day. This event aimed to promote
//                 linguistic diversity and cultural pride among students.
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="projcard projcard-red">
//           <div className="projcard-innerbox">
//             <img
//               className="projcard-img"
//               src="assets/img/events/SASTRAUniversity.jpg"
//             />
//             <div className="projcard-textbox">
//               <div className="projcard-title">
//                 Field Trip to SASTRA University
//               </div>

//               <div className="projcard-bar"></div>
//               <div className="projcard-description">
//                 Students from Grades 9 and 10 visited SASTRA University, where
//                 an event organized by ISRO provided insights into Space science
//                 and technology, broadening their knowledge and interests in the
//                 field.
//               </div>
//             </div>
//           </div>
//         </div>
//         <div
//           className="projcard projcard-customcolor"
//           style={{ color: " #F5AF41" }}
//         >
//           <div className="projcard-innerbox">
//             <img
//               className="projcard-img"
//               src="assets/img/events/Farewell12.jpg"
//             />
//             <div className="projcard-textbox">
//               <div className="projcard-title">
//                 Farewell for Grade 12 Students
//               </div>

//               <div className="projcard-bar"></div>
//               <div className="projcard-description">
//                 {" "}
//                 A heartfelt farewell was organized for our Grade 12 students,
//                 marking the end of their journey with us and celebrating their
//                 achievements and contributions.
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="projcard projcard-red">
//           <div className="projcard-innerbox">
//             <img
//               className="projcard-img"
//               src="assets/img/events/gallery16.jpg"
//             />
//             <div className="projcard-textbox">
//               <div className="projcard-title">Extracurricular Activities</div>

//               <div className="projcard-bar"></div>
//               <div className="projcard-description">
//                 Throughout the year, extracurricular activities such as Yoga,
//                 Silambam, Karate, Robotics and Art and Craft continued to enrich
//                 the students' educational experience, promoting holistic
//                 development.
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="projcard projcard-blue">
//           <div className="projcard-innerbox">
//             <img
//               className="projcard-img"
//               src="assets/img/events/gallery14.jpg"
//             />
//             <div className="projcard-textbox">
//               <div className="projcard-title">
//                 PT Periods, Smart Classes, and Computer Classes
//               </div>
//               <div className="projcard-bar"></div>
//               <div className="projcard-description">
//                 Regular Physical Training (PT) periods, smart classes, and
//                 computer classes were conducted to support physical fitness,
//                 modern learning techniques, and digital literacy.
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Events;
import React, { useEffect, useState } from "react";
import { fetchnewsevent } from "./Apiservice";

const Events = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadEvents = async () => {
      try {
        const data = await fetchnewsevent();
        setEvents(data);
      } catch (error) {
        setError("Failed to load events.");
      } finally {
        setLoading(false);
      }
    };

    loadEvents();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <div className="page-title" data-aos="fade">
        <div className="heading" id="event-banner">
          <div className="container">
            <div className="row d-flex justify-content-center text-center"></div>
          </div>
        </div>
      </div>

      <div className="projcard-container">
        {events.map((event) => (
          <div key={event.id} className={`projcard projcard-blue`}>
            <div className="projcard-innerbox">
              <img
                className="projcard-img"
                src={event.image}
                alt={event.name}
              />
              <div className="projcard-textbox">
                <div className="projcard-title">{event.name}</div>
                <div className="projcard-bar"></div>
                <div className="projcard-description">{event.description}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Events;
