import React from "react";

const Vision = () => {
  return (
    <div>
      <div class="page-title" data-aos="fade">
        <div class="heading" id="vision-banner">
          <div class="container">
            <div class="row d-flex justify-content-center text-center">
              {/* <div class="col-lg-8 mt-lg-4">
                <h1>Vision & Mission</h1>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <section id="about-us" class="section about-us">
        <div class="container">
          <div class="row gy-4">
            <div
              class="col-lg-6 order-1 order-lg-2 about-img"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <img
                src="assets/img/vision/vision.jpg"
                class="img-fluid about-img-1"
                alt="about-img"
                style={{ borderRadius: "10px" }}
              />
            </div>

            <div
              class="col-lg-6 order-2 order-lg-1 content"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <h3>Vision: </h3>
              <p class="fst-italic">
                At St. Marcinas, our vision is to foster an inclusive and
                nurturing educational environment that inspires students to
                achieve their highest potential. We strive to develop
                compassionate, innovative, and forward-thinking leaders who will
                positively impact their communities and the world. Our
                commitment is to academic excellence, personal growth, and
                holistic development, ensuring that every student is equipped
                with the skills and values necessary to thrive in a dynamic
                global society.
              </p>
              <h3>Mission: </h3>
              <p class="fst-italic">
                The mission of ST. MARCINA'S SCHOOL is to provide a rigorous and
                well-rounded education that empowers students to excel
                academically, socially, and morally. We are dedicated to
                creating a supportive and collaborative learning atmosphere
                where each student is encouraged to explore their passions,
                embrace challenges, and develop a lifelong love of learning.
                Through a balanced curriculum, dedicated faculty, and strong
                community partnerships, we aim to nurture responsible,
                respectful, and resilient individuals who are prepared to
                contribute meaningfully to society.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Vision;
