import React from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { HiArrowSmRight } from "react-icons/hi";
import Insta from "./Insta";

const Home = () => {
  React.useEffect(() => {
    AOS.init(); // Initialize AOS on component mount
  }, []);

  return (
    <div>
      {/* <section id="hero" className="hero section" style={{ opacity: "none" }}> */}
      {/* <section id="hero" className="hero section dark-background"> */}
      {/* <img
          src="assets/img/pic1.jpg"
          alt="Hero Background"
          data-aos="fade-in"
        /> */}
      {/* <div className="container">
          <h2 data-aos="fade-up" data-aos-delay="100">
            Learning Today,
            <br /> Leading Tomorrow
          </h2>
          <p data-aos="fade-up" data-aos-delay="200">
            For God And India
          </p>
          <div className="d-flex mt-4" data-aos="fade-up" data-aos-delay="300">
            <Link to="/Enquiry" className="btn-get-started">
              Get Started
            </Link>
          </div>
        </div> */}
      {/* </section> */}
      <div className="homebanner">
        <img
          src="assets/img/pic1.jpg"
          alt="Hero Background"
          data-aos="fade-in"
        />
      </div>
      <div className="container mt-5">
        <div className="homesecond-cards">
          <div className="homesecond-card">
            <div className="homesecond-card__img">
              <img src="/assets/img/homepage/home-notice.jpg" alt="" />
            </div>
            <div className="homesecond-cart__content">
              <h2>Notice Board</h2>
              <p>
                Check here for important updates, announcements, and notices.
                Stay informed about key dates and essential information for our
                school community.
              </p>

              <Link to="/about" className="read-more">
                See more
                <i className="bi bi-arrow-right"></i>
              </Link>
            </div>
          </div>

          <div className="homesecond-card">
            <div className="homesecond-card__img">
              <img src="/assets/img/homepage/home-newsevents.jpg" alt="" />
            </div>
            <div className="homesecond-cart__content">
              <h2>News & Events</h2>
              <p>
                Stay updated with the latest news and upcoming events at our
                school. Discover exciting activities, important announcements,
                and more!
              </p>

              <Link to="/News&events" className="read-more">
                See more
                <i className="bi bi-arrow-right"></i>
              </Link>
            </div>
          </div>

          <div className="homesecond-card">
            <div className="homesecond-card__img">
              <img src="/assets/img/homepage/home-gallery.jpg" alt="" />
            </div>
            <div className="homesecond-cart__content">
              <h2>Gallery</h2>
              <p>
                Explore our vibrant gallery showcasing classrooms, events, and
                student achievements, reflecting the heart of our school
                community.
              </p>

              <Link to="/gallery" className="read-more">
                See more
                <i className="bi bi-arrow-right"></i>
              </Link>
            </div>
          </div>
          <div className="homesecond-card">
            <div className="homesecond-card__img">
              <img src="/assets/img/school2.jpg" alt="" />
            </div>
            <div className="homesecond-cart__content">
              <h2>Enquiry</h2>
              <p>
                Have questions or need more information? Contact us for
                assistance and we'll be happy to help with your enquiries.
              </p>

              <Link to="/Enquiry" className="read-more">
                See more
                <i className="bi bi-arrow-right"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* ------------------------------------------------ desk------------------------------------ */}
      <div className="PRINCIPAL-DESK container mt-5">
        <div className="PRINCIPAL-DESK-content">
          <h3>PRINCIPAL's-DESK</h3>
          <p>
            Founded in 1982, Our school is situated in the serene rural village
            of Ammapet. Established by Smt. J. Annavadivu and run by the
            Annavadivu Educational Trust, our institution was born from a deep
            commitment to providing quality education to all..
          </p>
        </div>
        <div className="PRINCIPAL-DESK-student">
          <p>
            7,500+
            <br></br>ALUMNI
          </p>
        </div>
      </div>
      {/* ------------------------------------------------ home-gallery------------------------------------ */}
      {/* <div className="home-gallery mt-5">
        <div className="home-galleryheader">
          <h3>Gallery</h3>
          <div>
            <Link to="/gallery" className="read-more">
              View More
              <i className="bi bi-arrow-right"></i>
            </Link>
          </div>
        </div>

        <div className="container">
          <img src="/assets/img/gallery/gallery18.jpg" alt="img"></img>
          <img src="/assets/img/gallery/gallery15.jpg" alt="img"></img>
          <img src="/assets/img/gallery/gallery20.jpg" alt="img"></img>
          <img src="/assets/img/gallery/gallery9.jpg" alt="img"></img>
          <img src="/assets/img/gallery/gallery10.jpg" alt="img"></img>
          <img src="/assets/img/gallery/gallery12.jpg" alt="img"></img>
        </div>
      </div> */}
      {/* ------------------------------------------------ News & Events------------------------------------ */}
      <div className="container mt-5 mb-5">
        <div className="home-galleryheader">
          <h3>News & Events</h3>
          <div>
            <Link to="/News&events" className="read-more">
              View More
              <i className="bi bi-arrow-right"></i>
            </Link>
          </div>
        </div>
        <div className="homesecond-cards">
          <div className="homesecond-card">
            <div className="homesecond-card__img">
              <img
                src="/assets/img/homepage/home-notice.jpg"
                alt="img"
                style={{ borderRadius: "7px" }}
              />
            </div>
            {/* <div className="homesecond-cart__content ">
              <p>Independence Day & Founder’s Day</p>
            </div> */}
          </div>

          <div className="homesecond-card">
            <div className="homesecond-card__img">
              <img
                src="/assets/img/homepage/home-newsevents.jpg"
                alt="img"
                style={{ borderRadius: "7px" }}
              />
            </div>
            {/* <div className="homesecond-cart__content">
              <p>International Yoga Day</p>
            </div> */}
          </div>

          <div className="homesecond-card">
            <div className="homesecond-card__img">
              <img
                src="/assets/img/homepage/home-gallery.jpg"
                alt="img"
                style={{ borderRadius: "7px" }}
              />
            </div>
            {/* <div className="homesecond-cart__content">
              <p>Pongal Celebration</p>
            </div> */}
          </div>
        </div>
      </div>
      <Insta />
    </div>
  );
};

export default Home;
