// import React, { useState } from "react";
// import { Link } from "react-router-dom";

// const images = [
//   {
//     src: "assets/img/gallery/gallery3.jpg",
//     text: "Lorem ipsum dolor sit amet elit.",
//   },
//   {
//     src: "assets/img/gallery/gallery4.jpg",
//     text: "Lorem ipsum dolor sit amet elit.",
//   },
//   {
//     src: "assets/img/gallery/gallery2.jpg",
//     text: "Lorem ipsum dolor sit amet elit.",
//   },
//   {
//     src: "assets/img/gallery/gallery1.jpg",
//     text: "Lorem ipsum dolor sit amet elit.",
//   },
//   {
//     src: "assets/img/gallery/gallery5.jpg",
//     text: "Lorem ipsum dolor sit amet elit.",
//   },
//   {
//     src: "assets/img/gallery/gallery6.jpg",
//     text: "Lorem ipsum dolor sit amet elit.",
//   },
//   {
//     src: "assets/img/gallery/gallery7.jpg",
//     text: "Lorem ipsum dolor sit amet elit.",
//   },
//   {
//     src: "assets/img/gallery/gallery8.jpg",
//     text: "Lorem ipsum dolor sit amet elit.",
//   },
//   {
//     src: "assets/img/gallery/gallery9.jpg",
//     text: "Lorem ipsum dolor sit amet elit.",
//   },
//   {
//     src: "assets/img/gallery/gallery10.jpg",
//     text: "Lorem ipsum dolor sit amet elit.",
//   },
//   {
//     src: "assets/img/gallery/gallery21.jpg",
//     text: "Lorem ipsum dolor sit amet elit.",
//   },
//   {
//     src: "assets/img/gallery/gallery12.jpg",
//     text: "Lorem ipsum dolor sit amet elit.",
//   },
//   {
//     src: "assets/img/gallery/gallery13.jpg",
//     text: "Lorem ipsum dolor sit amet elit.",
//   },
//   {
//     src: "assets/img/gallery/gallery14.jpg",
//     text: "Lorem ipsum dolor sit amet elit.",
//   },
//   {
//     src: "assets/img/gallery/gallery15.jpg",
//     text: "Lorem ipsum dolor sit amet elit.",
//   },
//   {
//     src: "assets/img/gallery/gallery16.jpg",
//     text: "Lorem ipsum dolor sit amet elit.",
//   },
//   {
//     src: "assets/img/gallery/gallery17.jpg",
//     text: "Lorem ipsum dolor sit amet elit.",
//   },
//   {
//     src: "assets/img/gallery/gallery18.jpg",
//     text: "Lorem ipsum dolor sit amet elit.",
//   },
//   {
//     src: "assets/img/gallery/gallery19.jpg",
//     text: "Lorem ipsum dolor sit amet elit.",
//   },
//   {
//     src: "assets/img/gallery/gallery20.jpg",
//     text: "Lorem ipsum dolor sit amet elit.",
//   },
//   {
//     src: "assets/img/gallery/gallery21.jpg",
//     text: "Lorem ipsum dolor sit amet elit.",
//   },
// ];

// const Gallery = () => {
//   const [modalOpen, setModalOpen] = useState(false);
//   const [currentIndex, setCurrentIndex] = useState(0);

//   const openModal = (index) => {
//     setCurrentIndex(index);
//     setModalOpen(true);
//   };

//   const closeModal = () => {
//     setModalOpen(false);
//   };

//   const prevImage = () => {
//     setCurrentIndex(currentIndex > 0 ? currentIndex - 1 : images.length - 1);
//   };

//   const nextImage = () => {
//     setCurrentIndex(currentIndex < images.length - 1 ? currentIndex + 1 : 0);
//   };

//   return (
//     <div>
//       <div class="page-title" data-aos="fade">
//         <div class="heading" id="Gallery-banner">
//           <div class="container">
//             <div class="row d-flex justify-content-center text-center">
//               <div class="col-lg-8 mt-lg-5">
//                 <h1>Gallery</h1>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="gallery mt-5 mb-5">
//         {images.map((image, index) => (
//           <div
//             className="gallery-item"
//             key={index}
//             onClick={() => openModal(index)}
//           >
//             <img src={image.src} alt={image.text} />
//           </div>
//         ))}
//       </div>

//       {modalOpen && (
//         <div id="modal" className="modal">
//           <span className="close" onClick={closeModal}>
//             &times;
//           </span>
//           <div className="modal-content">
//             <div className="modal-left">
//               <img id="modal-img" src={images[currentIndex].src} alt="Modal" />
//             </div>
//             <div className="modal-right">
//               <p id="modal-text">{images[currentIndex].text}</p>
//               <button className="nav-btn" onClick={prevImage}>
//                 &#9664;
//               </button>
//               <button className="nav-btn" onClick={nextImage}>
//                 &#9654;
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// // export default Gallery;
// import React, { useState, useEffect } from "react";
// import { fetchgallery } from "./Apiservice";

// const Gallery = () => {
//   const [images, setImages] = useState([]);
//   const [modalOpen, setModalOpen] = useState(false);
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const galleryData = await fetchgallery();
//         setImages(galleryData);
//         setLoading(false);
//       } catch (err) {
//         setError(err);
//         setLoading(false);
//       }
//     };
//     fetchData();
//   }, []);

//   const openModal = (index) => {
//     setCurrentIndex(index);
//     setModalOpen(true);
//   };

//   const closeModal = () => {
//     setModalOpen(false);
//   };

//   const prevImage = () => {
//     setCurrentIndex(currentIndex > 0 ? currentIndex - 1 : images.length - 1);
//   };

//   const nextImage = () => {
//     setCurrentIndex(currentIndex < images.length - 1 ? currentIndex + 1 : 0);
//   };

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>Error: {error.message}</div>;
//   }

//   return (
//     <div>
//       <div className="page-title" data-aos="fade">
//         <div className="heading" id="Gallery-banner">
//           <div className="container">
//             <div className="row d-flex justify-content-center text-center">
//               <div className="col-lg-8 mt-lg-5">
//                 <h1>Gallery</h1>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="gallery mt-5 mb-5">
//         {images.map((image, index) => (
//           <div
//             className="gallery-item"
//             key={index}
//             onClick={() => openModal(index)}
//           >
//             <img src={image.src} alt={image.text} />
//           </div>
//         ))}
//       </div>

//       {modalOpen && (
//         <div id="modal" className="modal">
//           <span className="close" onClick={closeModal}>
//             &times;
//           </span>
//           <div className="modal-content">
//             <div className="modal-left">
//               <img id="modal-img" src={images[currentIndex].src} alt="Modal" />
//             </div>
//             <div className="modal-right">
//               <p id="modal-text">{images[currentIndex].text}</p>
//               <button className="nav-btn" onClick={prevImage}>
//                 &#9664;
//               </button>
//               <button className="nav-btn" onClick={nextImage}>
//                 &#9654;
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Gallery;

// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import { fetchgallery } from "./Apiservice";

// const Gallery = () => {
//   const [modalOpen, setModalOpen] = useState(false);
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [images, setImages] = useState([]);

//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const galleryData = await fetchgallery();
//         setImages(galleryData);
//         setLoading(false);
//       } catch (err) {
//         setError(err);
//         setLoading(false);
//       }
//     };
//     fetchData();
//   }, []);

//   const openModal = (index) => {
//     setCurrentIndex(index);
//     setModalOpen(true);
//   };

//   const closeModal = () => {
//     setModalOpen(false);
//   };

//   const prevImage = () => {
//     setCurrentIndex(currentIndex > 0 ? currentIndex - 1 : images.length - 1);
//   };

//   const nextImage = () => {
//     setCurrentIndex(currentIndex < images.length - 1 ? currentIndex + 1 : 0);
//   };

//   return (
//     <div>
//       <div class="page-title" data-aos="fade">
//         <div class="heading" id="Gallery-banner">
//           <div class="container">
//             <div class="row d-flex justify-content-center text-center">
//               <div class="col-lg-8 mt-lg-5">
//                 <h1>Gallery</h1>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="gallery mt-5 mb-5">
//         {images.map((image, index) => (
//           <div
//             className="gallery-item"
//             key={index}
//             onClick={() => openModal(index)}
//           >
//             <img src={image.src} alt={image.text} />
//           </div>
//         ))}
//       </div>

//       {modalOpen && (
//         <div id="modal" className="modal">
//           <span className="close" onClick={closeModal}>
//             &times;
//           </span>
//           <div className="modal-content">
//             <div className="modal-left">
//               <img id="modal-img" src={images[currentIndex].src} alt="Modal" />
//             </div>
//             <div className="modal-right">
//               <p id="modal-text">{images[currentIndex].text}</p>
//               <button className="nav-btn" onClick={prevImage}>
//                 &#9664;
//               </button>
//               <button className="nav-btn" onClick={nextImage}>
//                 &#9654;
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Gallery;

import React, { useState, useEffect } from "react";
import { fetchgallery } from "./Apiservice";

const Gallery = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const galleryData = await fetchgallery();
        setImages(galleryData);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const openModal = (index) => {
    setCurrentIndex(index);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const prevImage = () => {
    setCurrentIndex(currentIndex > 0 ? currentIndex - 1 : images.length - 1);
  };

  const nextImage = () => {
    setCurrentIndex(currentIndex < images.length - 1 ? currentIndex + 1 : 0);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading gallery: {error.message}</div>;

  return (
    <div>
      <div className="page-title" data-aos="fade">
        <div className="heading" id="Gallery-banner">
          <div className="container">
            <div className="row d-flex justify-content-center text-center">
              {/* <div className="col-lg-8 mt-lg-5">
                <h1>Gallery</h1>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="gallery mt-5 mb-5">
        {images.map((image, index) => (
          <div
            className="gallery-item"
            key={image.id}
            onClick={() => openModal(index)}
          >
            <img src={image.image} alt={image.name} />
          </div>
        ))}
      </div>

      {modalOpen && (
        <div id="modal" className="modal">
          <span className="close" onClick={closeModal}>
            &times;
          </span>
          <div className="modal-content">
            <div className="modal-left">
              <img
                id="modal-img"
                src={images[currentIndex].image}
                alt="Modal"
              />
            </div>
            <div className="modal-right">
              <p id="modal-text">{images[currentIndex].name}</p>
              <button className="nav-btn" onClick={prevImage}>
                &#9664;
              </button>
              <button className="nav-btn" onClick={nextImage}>
                &#9654;
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
