// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import { IoCloseSharp } from "react-icons/io5";

// const Header = () => {
//   const [isNavOpen, setIsNavOpen] = useState(false);

//   const toggleNav = () => {
//     setIsNavOpen((prevState) => !prevState);
//   };

//   const toggleNavclose = () => {
//     setIsNavOpen(true);
//   };

//   return (
//     <header id="header" className="header d-flex align-items-center sticky-top">
//       <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
//         <Link to="/" className="logo d-flex align-items-center">
//           <img src="assets/img/Logo.png" alt="Logo" />
//           <h1 className="sitename">ST. MARCINA'S SCHOOL</h1>
//         </Link>

//         <nav
//           id="navmenu"
//           className={`navmenu ${isNavOpen ? "mobile-nav-active" : ""}`}
//         >
//           <ul>
//             <li>
//               <Link to="/" className="active">
//                 Home
//               </Link>
//             </li>
//             <li>
//               <Link to="/about">About us</Link>
//             </li>
//             <li>
//               <Link to="/Vision">Vision & Mission</Link>
//             </li>
//             <li>
//               <Link to="/Facilities"> Facilities</Link>
//             </li>

//             <li>
//               <Link to="/News&events">News & Events</Link>
//             </li>
//             {/* <li>
//               <Link to="/Faculty"> Faculty</Link>
//             </li> */}
//             <li>
//               <Link to="/gallery">Gallery</Link>
//             </li>
//             <li>
//               <Link to="/contact">Contact</Link>
//             </li>
//             <li>
//               <Link
//                 to="/Enquiry"
//                 style={{
//                   padding: "10px 20px 10px 20px ",
//                   backgroundColor: " rgba(36, 28, 105, 255)",
//                   borderRadius: "25px",
//                   color: "white",
//                 }}
//               >
//                 Enquiry
//               </Link>
//             </li>
//           </ul>
//         </nav>

//         <i
//           className={`mobile-nav-toggle bi ${isNavOpen ? "" : "bi-list"}`}
//           onClick={toggleNav}
//         ></i>
//       </div>

//       {isNavOpen && (
//         <div className="mobile-menu-overlay" onClick={toggleNav}>
//           <div className="mobile-menu-content">
//             <i className=""></i>
//             <div
//               style={{ fontSize: "25px", marginLeft: "220px" }}
//               onClick={toggleNavclose}
//             >
//               <IoCloseSharp />
//             </div>

//             <ul>
//               <li>
//                 <Link to="/" onClick={toggleNavclose}>
//                   Home
//                 </Link>
//               </li>
//               <li>
//                 <Link to="/about" onClick={toggleNavclose}>
//                   About us
//                 </Link>
//               </li>
//               <li>
//                 <Link to="/Vision" onClick={toggleNavclose}>
//                   Vision & Mission
//                 </Link>
//               </li>

//               <li>
//                 <Link to="/Facilities" onClick={toggleNavclose}>
//                   {" "}
//                   Facilities
//                 </Link>
//               </li>
//               <li>
//                 <Link to="/News&events" onClick={toggleNavclose}>
//                   News & Events
//                 </Link>
//               </li>
//               {/* <li>
//                 <Link to="/Faculty" onClick={toggleNavclose}>
//                   {" "}
//                   Faculty
//                 </Link>
//               </li> */}
//               <li>
//                 <Link to="/gallery" onClick={toggleNavclose}>
//                   Gallery
//                 </Link>
//               </li>
//               <li>
//                 <Link to="/contact" onClick={toggleNavclose}>
//                   Contact
//                 </Link>
//                 <li>
//                   <Link
//                     to="/Enquiry"
//                     onClick={toggleNavclose}
//                     style={{
//                       padding: "10px 20px 10px 20px ",
//                       backgroundColor: " rgba(36, 28, 105, 255)",
//                       borderRadius: "25px",
//                       color: "white",
//                     }}
//                   >
//                     Enquiry
//                   </Link>
//                 </li>
//               </li>
//             </ul>
//           </div>
//         </div>
//       )}
//     </header>
//   );
// };

// export default Header;
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { IoCloseSharp } from "react-icons/io5";
import { BiMenu } from "react-icons/bi";

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const location = useLocation(); // Get the current route

  const toggleNav = () => {
    setIsNavOpen((prevState) => !prevState);
  };

  const closeNav = () => {
    setIsNavOpen(false); // Ensure the nav closes when a link is clicked or overlay is clicked
  };

  return (
    <header id="header" className="header sticky-top d-flex align-items-center">
      <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
        <Link to="/" className="logo d-flex align-items-center">
          <img src="assets/img/Logo.png" alt="Logo" />
          <h1 className="sitename">ST. MARCINA'S SCHOOL</h1>
        </Link>

        <nav
          id="navmenu"
          className={`navmenu ${isNavOpen ? "mobile-nav-active" : ""}`}
        >
          <ul>
            <li>
              <Link
                to="/"
                className={location.pathname === "/" ? "active" : ""}
                onClick={closeNav}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className={location.pathname === "/about" ? "active" : ""}
                onClick={closeNav}
              >
                About us
              </Link>
            </li>
            <li>
              <Link
                to="/Vision"
                className={location.pathname === "/Vision" ? "active" : ""}
                onClick={closeNav}
              >
                Vision & Mission
              </Link>
            </li>
            <li>
              <Link
                to="/Facilities"
                className={location.pathname === "/Facilities" ? "active" : ""}
                onClick={closeNav}
              >
                Facilities
              </Link>
            </li>
            <li>
              <Link
                to="/News&events"
                className={location.pathname === "/News&events" ? "active" : ""}
                onClick={closeNav}
              >
                News & Events
              </Link>
            </li>
            <li>
              <Link
                to="/gallery"
                className={location.pathname === "/gallery" ? "active" : ""}
                onClick={closeNav}
              >
                Gallery
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className={location.pathname === "/contact" ? "active" : ""}
                onClick={closeNav}
              >
                Contact
              </Link>
            </li>
            <li>
              <Link
                to="/Enquiry"
                className={location.pathname === "/Enquiry" ? "active" : ""}
                onClick={closeNav}
                style={{
                  padding: "10px 20px",
                  backgroundColor: "rgba(36, 28, 105, 255)",
                  borderRadius: "25px",
                  color: "white",
                }}
              >
                Enquiry
              </Link>
            </li>
          </ul>
        </nav>

        {/* Toggle button for mobile menu */}
        <i className="mobile-nav-toggle" onClick={toggleNav}>
          {isNavOpen ? <IoCloseSharp size={28} /> : <BiMenu size={28} />}
        </i>
      </div>

      {/* Overlay for mobile menu */}
      {isNavOpen && (
        <div className="mobile-menu-overlay" onClick={closeNav}>
          <div className="mobile-menu-content">
            <ul>
              <li>
                <Link
                  to="/"
                  className={location.pathname === "/" ? "active" : ""}
                  onClick={closeNav}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  className={location.pathname === "/about" ? "active" : ""}
                  onClick={closeNav}
                >
                  About us
                </Link>
              </li>
              <li>
                <Link
                  to="/Vision"
                  className={location.pathname === "/Vision" ? "active" : ""}
                  onClick={closeNav}
                >
                  Vision & Mission
                </Link>
              </li>
              <li>
                <Link
                  to="/Facilities"
                  className={
                    location.pathname === "/Facilities" ? "active" : ""
                  }
                  onClick={closeNav}
                >
                  Facilities
                </Link>
              </li>
              <li>
                <Link
                  to="/News&events"
                  className={
                    location.pathname === "/News&events" ? "active" : ""
                  }
                  onClick={closeNav}
                >
                  News & Events
                </Link>
              </li>
              <li>
                <Link
                  to="/gallery"
                  className={location.pathname === "/gallery" ? "active" : ""}
                  onClick={closeNav}
                >
                  Gallery
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className={location.pathname === "/contact" ? "active" : ""}
                  onClick={closeNav}
                >
                  Contact
                </Link>
              </li>
              <li>
                <Link
                  to="/Enquiry"
                  className={location.pathname === "/Enquiry" ? "active" : ""}
                  onClick={closeNav}
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "rgba(36, 28, 105, 255)",
                    borderRadius: "25px",
                    color: "white",
                  }}
                >
                  Enquiry
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
