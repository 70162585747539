import axios from "axios";

const BASE_URL = "https://demo.stmarcinaschool.com/api/api/";

const ApiService = axios.create({
  baseURL: BASE_URL,
});

export const fetchgallery = async () => {
  try {
    const response = await ApiService.get("gallery/");
    return response.data;
  } catch (error) {
    console.error("Error during Category fetch", error);
    throw error;
  }
};
export const submitContactForm = async (formData) => {
  try {
    const response = await ApiService.post("contact/", formData);
    return response.data;
  } catch (error) {
    console.error("Error during contact form submission", error);
    throw error;
  }
};
export const fetchnewsevent = async () => {
  try {
    const response = await ApiService.get("events/");
    return response.data;
  } catch (error) {
    console.error("Error during Category fetch", error);
    throw error;
  }
};

// export const fetchbrandlist = async () => {
//   try {
//     const response = await ApiService.get("brand-list/");
//     return response.data;
//   } catch (error) {
//     console.error("Error during Category fetch", error);
//     throw error;
//   }
// };
// export const fetchfilter = async (categoryId) => {
//   try {
//     const response = await ApiService.get(`filter/?category=${categoryId}`);
//     return response.data;
//   } catch (error) {
//     console.error("Error during Category fetch", error);
//     throw error;
//   }
// };

// export const fetchsearch = async (searchTerm) => {
//   try {
//     const response = await ApiService.get("search/", {
//       params: { query: searchTerm },
//     });
//     return response.data;
//   } catch (error) {
//     console.error("Error during search fetch", error);
//     throw error;
//   }
// // };
// export const fetchsearch = async (searchQuery) => {
//   try {
//     const response = await ApiService.get(`search/?search=${searchQuery}`);
//     return response.data;
//   } catch (error) {
//     console.error("Error searching tours:", error);
//     throw error;
//   }
// };

// export const fetchProductDetail = async (id) => {
//   try {
//     const response = await axios.get(`${BASE_URL}product-detail/${id}`);
//     return response.data;
//   } catch (error) {
//     console.error("Error fetching product detail:", error);
//     throw error;
//   }
// };

// export const fetchbrandproduct = async (brandId) => {
//   try {
//     const response = await axios.get(`${BASE_URL}product-brand/${brandId}`);
//     return response.data;
//   } catch (error) {
//     console.error("Error fetching product detail:", error);
//     throw error;
//   }
// };
