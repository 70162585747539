import React from "react";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div>
      <div class="page-title" data-aos="fade">
        <div class="heading" id="About-banner">
          <div class="container">
            <div class="row d-flex justify-content-center text-center">
              {/* <div class="col-lg-8 mt-lg-4">
                <h1>About</h1>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <section id="about-us" class="section about-us">
        <div class="container">
          <div class="row gy-4">
            <div
              class="col-lg-6 order-1 order-lg-2 about-img"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <img
                src="assets/img/pic2.jpg"
                class="img-fluid about-img-1"
                alt="about-img"
                style={{ borderRadius: "10px" }}
              />
            </div>

            <div
              class="col-lg-6 order-2 order-lg-1 content"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <h3>About </h3>
              <p class="fst-italic">
                Founded in 1982, Our school is situated in the serene rural
                village of Ammapet. Established by Smt. J. Annavadivu and run by
                the Annavadivu Educational Trust, our institution was born from
                a deep commitment to providing quality education to all.
              </p>

              <p class="fst-italic">
                Guided by our motto, "For God and India," we are dedicated to
                fostering a learning environment that honours both spiritual
                values and national pride. Our goal is to equip students with
                the knowledge, skills, and values they need to contribute
                meaningfully to society.
              </p>
              <p class="fst-italic">
                Join us as we continue to honour our Founder's vision and work
                towards a brighter future for every student.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
