import React, { useState } from "react";
import { submitContactForm } from "./Apiservice";

const Enquiry = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    location: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      await submitContactForm(formData);
      setSuccessMessage("Your message has been sent. Thank you!");
      setFormData({
        name: "",
        email: "",
        mobile: "",
        location: "",
        message: "",
      });
    } catch (err) {
      setError("Error submitting the form. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="Enquiry">
      <div className="Enquiryform-main">
        <div className="Enquirymain-wrapper">
          <h2 className="Enquiryform-head">Enquiry Form</h2>
          <form className="Enquiryform-wrapper" onSubmit={handleSubmit}>
            <div className="Enquiryform-card">
              <input
                className="Enquiryform-input"
                type="text"
                name="name"
                id="full_name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <label className="Enquiryform-label" htmlFor="full_name">
                Full Name
              </label>
            </div>
            <div className="Enquiryform-card">
              <input
                className="Enquiryform-input"
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <label className="Enquiryform-label" htmlFor="email">
                Email
              </label>
            </div>
            <div className="Enquiryform-card">
              <input
                className="Enquiryform-input"
                type="tel"
                name="mobile"
                id="mobile"
                value={formData.mobile}
                onChange={handleChange}
                required
              />
              <label className="Enquiryform-label" htmlFor="mobile">
                Phone Number
              </label>
            </div>
            <div className="Enquiryform-card">
              <input
                className="Enquiryform-input"
                type="text"
                name="location"
                id="location"
                value={formData.location}
                onChange={handleChange}
                required
              />
              <label className="Enquiryform-label" htmlFor="location">
                Location
              </label>
            </div>
            <div className="Enquiryform-card">
              <textarea
                className="Enquiryform-textarea"
                maxLength="420"
                rows="3"
                name="message"
                id="message"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
              <label className="Enquiryform-textarea-label" htmlFor="message">
                Message
              </label>
            </div>
            <div className="Enquirybtn-wrap">
              <button type="submit" disabled={loading}>
                {loading ? "Submitting..." : "Submit"}
              </button>
            </div>
            {error && <p className="error-message">{error}</p>}
            {successMessage && (
              <p className="success-message">{successMessage}</p>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Enquiry;
