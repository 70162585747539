import React from "react";
import { Link } from "react-router-dom";
import { GoDotFill } from "react-icons/go";

const Facilities = () => {
  return (
    <div>
      <div class="page-title" data-aos="fade">
        <div class="heading " id="Facilities-banner">
          <div class="container ">
            <div class="row d-flex justify-content-center text-center">
              {/* <div class="col-lg-8 mt-lg-3">
                <h1>Facilities</h1>
              </div> */}
            </div>
          </div>
        </div>
        {/* <nav class="breadcrumbs">
          <div class="container">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li class="current">Facilities</li>
            </ol>
          </div>
        </nav> */}
      </div>
      <h3 className="text-center mt-3 content" id="Facilitiesheading">
        Facilities
      </h3>

      <div className="Facilities mb-3 container">
        <div className="Facilities-content">
          <ul className="custom-ul">
            <li className="custom-li">
              <GoDotFill style={{ marginRight: "5px", paddingBottom: "5px" }} />
              Furnished Classrooms: Designed to accommodate up to 4000 students
              with modern amenities.
            </li>
            <li className="custom-li">
              <GoDotFill style={{ marginRight: "5px", paddingBottom: "5px" }} />
              Sprawling 5-Acre Campus: Includes comprehensive sports and games
              facilities.
            </li>
            <li className="custom-li">
              <GoDotFill style={{ marginRight: "5px", paddingBottom: "5px" }} />
              Fully WiFi-Enabled Campus: Ensuring seamless connectivity
              throughout the school.
            </li>
            <li className="custom-li">
              <GoDotFill style={{ marginRight: "5px", paddingBottom: "5px" }} />
              CCTV Surveillance: Comprehensive coverage at all key points for
              enhanced security.
            </li>
            <li className="custom-li">
              <GoDotFill style={{ marginRight: "5px", paddingBottom: "5px" }} />
              Smart Classrooms: Equipped with Smart TVs to support contemporary
              educational methods.
            </li>
            <li className="custom-li">
              <GoDotFill style={{ marginRight: "5px", paddingBottom: "5px" }} />
              Specialized Laboratories: Dedicated labs for Physics, Chemistry,
              Biology, and Computer Science.
            </li>
            <li className="custom-li">
              <GoDotFill style={{ marginRight: "5px", paddingBottom: "5px" }} />
              Ergonomic Furniture: Comfortable wooden furniture provided in all
              classrooms and labs.
            </li>
            <li className="custom-li">
              <GoDotFill style={{ marginRight: "5px", paddingBottom: "5px" }} />
              Exceptional Infrastructure: Set in a serene, lush green
              environment, promoting a conducive learning atmosphere.
            </li>
            <li className="custom-li">
              <GoDotFill style={{ marginRight: "5px", paddingBottom: "5px" }} />
              Safe Transportation: Reliable transport facilities offered at a
              nominal cost.
            </li>
            <li className="custom-li">
              <GoDotFill style={{ marginRight: "5px", paddingBottom: "5px" }} />
              Diverse Extracurricular Activities: Includes Tamil, English,
              Vocal, Music, Dance, Drama, Road Safety, Anti-Drug and Eco Clubs.
            </li>
            <li className="custom-li">
              <GoDotFill style={{ marginRight: "5px", paddingBottom: "5px" }} />
              Year-Round Celebrations: Events such as Doctor’s Day, Engineer’s
              Day, Origami Day, World Savings Day, International Sudoku Day,
              World Wildlife Day, and more.
            </li>
            <li className="custom-li">
              <GoDotFill style={{ marginRight: "5px", paddingBottom: "5px" }} />
              Curriculum Enhancement: Introduction of Oxford University Press
              books to align with CBSE standards.
            </li>
            <li className="custom-li">
              <GoDotFill style={{ marginRight: "5px", paddingBottom: "5px" }} />
              Comprehensive Assessment: Regular Slip Tests, Weekly Tests, Cyclic
              Tests, Unit Tests, and Terminal Exams to gauge and enhance student
              performance.
            </li>
            <li className="custom-li">
              <GoDotFill style={{ marginRight: "5px", paddingBottom: "5px" }} />
              Language Focus: Emphasis on Mother Tongue, Spoken English, and
              Hindi.
            </li>
            <li className="custom-li">
              <GoDotFill style={{ marginRight: "5px", paddingBottom: "5px" }} />
              Special Training Classes: Offered in Origami, Yoga, Art & Craft,
              Karate, Silambam, and Robotics.
            </li>
            <li className="custom-li">
              <GoDotFill style={{ marginRight: "5px", paddingBottom: "5px" }} />
              NEET/IIT JEE Coaching: Specialized coaching for all the XI & XII
              students in collaboration with Blooming Academy.
            </li>
          </ul>
        </div>
        <div className="Facilities-image">
          <img src="/assets/img/facilites/facilites1.jpg" alt="Facility 1" />
          <img src="/assets/img/facilites/facilites3.jpeg" alt="Facility 3" />
        </div>
      </div>
    </div>
  );
};

export default Facilities;
